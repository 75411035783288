@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:500');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

* {
  font-weight: 500;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #434343;
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100vh;
  position: relative;
}

button,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: unset;
}

.fa {
  position: relative;
}

.fa::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.rtf.open .rtf--mb > * {
  transform-origin: unset !important;
  transform: unset !important;
  transition: unset !important;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-sm {
  line-height: 1.5;
  padding: 5px 10px;
}

.btn-grey {
  background-color: #f0f0f0;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}
