/* Navigation */

.react-calendar__navigation {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.react-calendar__navigation__label {
  flex-grow: 0 !important;
  border: 1px solid #34cb9e;
  background: #f1fbf8;
  border-radius: 5px;
  color: #34cb9e;
  padding: 0 30px;
  margin: 0 8px;
}

.react-calendar__navigation__label span {
  font-weight: 600;
}

.react-calendar__navigation__arrow {
  height: 32px;
  width: 32px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  background: #fff;
}

/* Week days */

.react-calendar__month-view__weekdays {
  margin-bottom: 10px;
}

.react-calendar__month-view__weekdays__weekday {
  color: #34cb9e;
  display: flex;
  justify-content: center;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

/* Dates */

.react-calendar__tile {
  background: #fff;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  margin: 2.5px;
  flex-basis: calc((100% / 7) - 5px) !important;
}

.react-calendar__tile--active {
  background: #f1fbf8;
  border: 1px solid #34cb9e;
  color: #34cb9e;
  font-weight: 600;
}
